import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/tmp/node_modules/@nib/gatsby-theme-mesh-docs/src/templates/Content.js";
import { Box } from '@nib/layout';
import Copy from '@nib-components/copy';
import Alert from '@nib-components/alert';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installation",
        "aria-label": "installation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Installation`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @nib/layout
`}</code></pre>
    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import {Box} from '@nib/layout';
`}</code></pre>
    <Alert type="warning" title="Do not style layout components" mdxType="Alert">
  You must not apply styled-components to this component as it will interfere with the structure required for the current styling. This is true for all layout components.
    </Alert>
    <h2 {...{
      "id": "interactive-demo",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#interactive-demo",
        "aria-label": "interactive demo permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Interactive demo`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Box padding={4} background="lightest" color="black" borderRadius="standard">
  <Copy>Lorem ipsum dolor sit amet.</Copy>
</Box>
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#props",
        "aria-label": "props permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`padding`}</inlineCode>{`, `}<inlineCode parentName="td">{`paddingVertical`}</inlineCode>{`, `}<inlineCode parentName="td">{`paddingHorizontal`}</inlineCode>{`, `}<inlineCode parentName="td">{`paddingTop`}</inlineCode>{`, `}<inlineCode parentName="td">{`paddingRight`}</inlineCode>{`, `}<inlineCode parentName="td">{`paddingBottom`}</inlineCode>{`, `}<inlineCode parentName="td">{`paddingLeft`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`number or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A size from our `}<a parentName="td" {...{
              "href": "/foundations/spacing/"
            }}>{`spacing scale`}</a>{`. Can be made responsive by passing an object of breakpoints. Value(s) must be one of `}<inlineCode parentName="td">{`1...10`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`margin`}</inlineCode>{`, `}<inlineCode parentName="td">{`marginVertical`}</inlineCode>{`, `}<inlineCode parentName="td">{`marginHorizontal`}</inlineCode>{`, `}<inlineCode parentName="td">{`marginTop`}</inlineCode>{`, `}<inlineCode parentName="td">{`marginRight`}</inlineCode>{`, `}<inlineCode parentName="td">{`marginBottom`}</inlineCode>{`, `}<inlineCode parentName="td">{`marginLeft`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`number or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A size from our `}<a parentName="td" {...{
              "href": "/foundations/spacing/"
            }}>{`spacing scale`}</a>{`. Can be made responsive by passing an object of breakpoints. Value(s) must be one of `}<inlineCode parentName="td">{`1...10`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`paddingBlockStart`}</inlineCode>{`, `}<inlineCode parentName="td">{`paddingInlineEnd`}</inlineCode>{`, `}<inlineCode parentName="td">{`paddingBlockEnd`}</inlineCode>{`, `}<inlineCode parentName="td">{`paddingInlineStart`}</inlineCode>{`, `}<inlineCode parentName="td">{`paddingBlock`}</inlineCode>{`, `}<inlineCode parentName="td">{`paddingInline`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`number or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A size from our `}<a parentName="td" {...{
              "href": "/foundations/spacing/"
            }}>{`spacing scale`}</a>{`. Can be made responsive by passing an object of breakpoints. Value(s) must be one of `}<inlineCode parentName="td">{`1...10`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`marginBlockStart`}</inlineCode>{`, `}<inlineCode parentName="td">{`marginInlineEnd`}</inlineCode>{`, `}<inlineCode parentName="td">{`marginBlockEnd`}</inlineCode>{`, `}<inlineCode parentName="td">{`marginInlineStart`}</inlineCode>{`, `}<inlineCode parentName="td">{`marginBlock`}</inlineCode>{`, `}<inlineCode parentName="td">{`marginInline`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`number or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A size from our `}<a parentName="td" {...{
              "href": "/foundations/spacing/"
            }}>{`spacing scale`}</a>{`. Can be made responsive by passing an object of breakpoints. Value(s) must be one of `}<inlineCode parentName="td">{`1...10`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`display`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`How the box should display. Can be made responsive by passing an object of breakpoints. Value(s) must be one of `}<inlineCode parentName="td">{`none`}</inlineCode>{`, `}<inlineCode parentName="td">{`inline`}</inlineCode>{`, `}<inlineCode parentName="td">{`block`}</inlineCode>{`, `}<inlineCode parentName="td">{`inline-block`}</inlineCode>{`, `}<inlineCode parentName="td">{`flex`}</inlineCode>{`, `}<inlineCode parentName="td">{`inline-flex`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`width`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The width of the box. Can be made responsive by passing an object of breakpoints. Value(s) must be a valid css value with units.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`minWidth`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The minimum width of the box, growing if content is less than the minWidth. Can be made responsive by passing an object of breakpoints. Value(s) must be a valid css value with units.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`maxWidth`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The maximum width of the box, shrinking if content is greater than the maxWidth. Can be made responsive by passing an object of breakpoints. Value(s) must be a valid css value with units.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`height`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The height of the box. Can be made responsive by passing an object of breakpoints. Value(s) must be a valid css value with units.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`minHeight`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The minimum height of the box, growing if content is less than the minHeight. Can be made responsive by passing an object of breakpoints. Value(s) must be a valid css value with units.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`maxHeight`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The maximum height of the box, shrinking if content is greater than the maxHeight. Can be made responsive by passing an object of breakpoints. Value(s) must be a valid css value with units.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`justifyContent`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`When display is set to `}<inlineCode parentName="td">{`flex/inline-flex`}</inlineCode>{` this aligns the Box children along the cross axis (horizontal by default). Can be made responsive by passing an object of breakpoints. Value(s) must be one of `}<inlineCode parentName="td">{`center`}</inlineCode>{`, `}<inlineCode parentName="td">{`flex-start`}</inlineCode>{`, `}<inlineCode parentName="td">{`flex-end`}</inlineCode>{`, `}<inlineCode parentName="td">{`space-between`}</inlineCode>{`, or `}<inlineCode parentName="td">{`space-around`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`alignItems`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`When display is set to `}<inlineCode parentName="td">{`flex/inline-flex`}</inlineCode>{` this aligns the Box children along the main axis (vertical by default). Can be made responsive by passing an object of breakpoints. Value(s) must be one of `}<inlineCode parentName="td">{`flex-start`}</inlineCode>{`, `}<inlineCode parentName="td">{`center`}</inlineCode>{`, or `}<inlineCode parentName="td">{`flex-end`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`flexDirection`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`When display is set to `}<inlineCode parentName="td">{`flex/inline-flex`}</inlineCode>{` this sets the main axis and direction of the Box. Can be made responsive by passing an object of breakpoints. Value(s) must be one of `}<inlineCode parentName="td">{`row`}</inlineCode>{`, `}<inlineCode parentName="td">{`column`}</inlineCode>{`, `}<inlineCode parentName="td">{`row-reverse`}</inlineCode>{`, or `}<inlineCode parentName="td">{`column-reverse`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`flexWrap`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`When display is set to `}<inlineCode parentName="td">{`flex/inline-flex`}</inlineCode>{` this sets whether flex items are forced onto one line or can wrap onto multiple lines. Can be made responsive by passing an object of breakpoints. Value(s) must be one of `}<inlineCode parentName="td">{`wrap`}</inlineCode>{` or `}<inlineCode parentName="td">{`nowrap`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`flexGrow`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`number or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`When Box is a flex-item this sets whether the Box should grow to fill remaining space. Can be made responsive by passing an object of breakpoints. Value(s) must be a number.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`flexShrink`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`number or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`When Box is a flex-item this sets whether the Box should shrink to fit within available space. Can be made responsive by passing an object of breakpoints. Value(s) must be a number.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`textAlign`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The text alignment of the Box. Can be made responsive by passing an object of breakpoints. Value(s) must be one of `}<inlineCode parentName="td">{`left`}</inlineCode>{`, `}<inlineCode parentName="td">{`center`}</inlineCode>{`, or `}<inlineCode parentName="td">{`right`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`background`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The background color of the Box. Can be made responsive by passing an object of breakpoints. Can be any of the `}<a parentName="td" {...{
              "href": "https://github.com/nib-group/design-system/blob/master/packages/layout/src/Box/lib/colors.ts#L55"
            }}><inlineCode parentName="a">{`validBgColorValues`}</inlineCode></a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The text color of the Box. Can be made responsive by passing an object of breakpoints. Can be any of the `}<a parentName="td" {...{
              "href": "https://github.com/nib-group/design-system/blob/master/packages/layout/src/Box/lib/colors.ts#L65"
            }}><inlineCode parentName="a">{`validFgColorValues`}</inlineCode></a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`borderRadius`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The border radius of the Box. Must be one of `}<inlineCode parentName="td">{`none`}</inlineCode>{`, `}<inlineCode parentName="td">{`small`}</inlineCode>{`, `}<inlineCode parentName="td">{`standard`}</inlineCode>{`, or `}<inlineCode parentName="td">{`full`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boxShadow`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The box shadow of the Box. Must be one of `}<inlineCode parentName="td">{`none`}</inlineCode>{` or `}<inlineCode parentName="td">{`standard`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`gap`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`number or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the gaps between children elements . A size from our `}<a parentName="td" {...{
              "href": "https://github.com/nib-group/design-system/blob/master/packages/theme/src/common/index.js#L13"
            }}>{`spacing scale`}</a>{`. Can be made responsive by passing an object of breakpoints. Value(s) must be one of `}<inlineCode parentName="td">{`1...10`}</inlineCode>{` values.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`lineHeight`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the line-height for the Box component. Must be valid line-height property values. Note: Copy component defines has it's own line-height`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`overflow`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the overflow property . Must be valid overflow property values.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`borderWidth`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the border width for the Box component . Value(s) must be a valid css value with units or valid borderWidth token values `}<inlineCode parentName="td">{`none`}</inlineCode>{`, `}<inlineCode parentName="td">{`fine`}</inlineCode>{`, `}<inlineCode parentName="td">{`thin`}</inlineCode>{`, `}<inlineCode parentName="td">{`medium`}</inlineCode>{` or `}<inlineCode parentName="td">{`thick`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`borderStyle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the border style for the Box component . Values must be one of `}<inlineCode parentName="td">{`none`}</inlineCode>{`, `}<inlineCode parentName="td">{`hidden`}</inlineCode>{`, `}<inlineCode parentName="td">{`dotted`}</inlineCode>{`, `}<inlineCode parentName="td">{`dashed`}</inlineCode>{`, `}<inlineCode parentName="td">{`solid`}</inlineCode>{`, `}<inlineCode parentName="td">{`double`}</inlineCode>{`, `}<inlineCode parentName="td">{`groove`}</inlineCode>{`, `}<inlineCode parentName="td">{`inset`}</inlineCode>{`, `}<inlineCode parentName="td">{`ridge`}</inlineCode>{`, `}<inlineCode parentName="td">{`outset`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`borderColor`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the border color for the Box component. Can be any of the `}<a parentName="td" {...{
              "href": "https://github.com/nib-group/design-system/blob/master/packages/layout/src/Box/lib/colors.ts#L89"
            }}><inlineCode parentName="a">{`validBorderColorValues`}</inlineCode></a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`position`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the position property . Must be one of `}<inlineCode parentName="td">{`static`}</inlineCode>{`, `}<inlineCode parentName="td">{`relative`}</inlineCode>{`, `}<inlineCode parentName="td">{`absolute`}</inlineCode>{`, `}<inlineCode parentName="td">{`fixed`}</inlineCode>{` or `}<inlineCode parentName="td">{`sticky`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`top`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Specifies the vertical position of a positioned element. It has no effect on non-positioned elements.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`bottom`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Specifies the vertical position of a positioned element. It has no effect on non-positioned elements.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`left`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Specifies the horizontal position of a positioned element. It has no effect on non-positioned elements.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`right`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Specifies the horizontal position of a positioned element. It has no effect on non-positioned elements.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`inset`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Shorthand that corresponds to the `}<inlineCode parentName="td">{`top`}</inlineCode>{`, `}<inlineCode parentName="td">{`right`}</inlineCode>{`, `}<inlineCode parentName="td">{`bottom`}</inlineCode>{`, and/or `}<inlineCode parentName="td">{`left`}</inlineCode>{` properties`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`isolate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Determines whether an element must create a new stacking context`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`zIndex`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`number or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the z-index property on Box component.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`children`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The contents of the Box.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "considerations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#considerations",
        "aria-label": "considerations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Considerations`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`Box`}</inlineCode>{` component can be used in place of a `}<inlineCode parentName="p">{`div`}</inlineCode>{` whenever padding, background color, text color, border radius, text alignment or basic flex styling needs to be applied to a component.`}</p>
    <p>{`Prior to this `}<inlineCode parentName="p">{`Box`}</inlineCode>{` component, you would have had to have done something like this to add common, standard styling to a component:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import styled from 'styled-components';
import {colorDarkest, colorLightest} from '@nib-components/theme';
import {m, p} from '@nib/layout';

const MyBox = styled.div\`
  \${m(5)};
  \${p({xs: 4, md: 6})};
  background-color: \${colorDarkest};
  border-radius: 4px;
  color: \${colorLightest};
  text-align: center;
\`;
`}</code></pre>
    <p>{`Now with `}<inlineCode parentName="p">{`Box`}</inlineCode>{` you do not need to step out of the component you are working on to import a bunch of boilerplate and define a styled component that meets your specific needs. Just use the props provided by `}<inlineCode parentName="p">{`Box`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Box margin={5} padding={{xs: 4, md: 6}} background="darkest" color="lightest" borderRadius="standard" textAlign="center">
  Hello world
</Box>
`}</code></pre>
    <h3 {...{
      "id": "dom-element",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#dom-element",
        "aria-label": "dom element permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`DOM element`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`Box`}</inlineCode>{` component is a styled div element. To change this you can use the styled components `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Box padding={4} as="aside">
  <Stack space={4}>
    <Heading size={4}>Newsletter</Heading>
    <Copy>Lorem ipsum dolor sit amet.</Copy>
    <Link href="/subsribe">Sign up now</Link>
  </Stack>
</Box>
`}</code></pre>
    <h3 {...{
      "id": "logical-properties",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#logical-properties",
        "aria-label": "logical properties permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Logical Properties`}</h3>
    <p>{`Logical properties and values in CSS provide a powerful way to control layout and style based on the flow of content. Unlike traditional physical properties (such as margin-top or padding-left), logical properties adapt to different writing modes (horizontal or vertical) and text directions (left-to-right or right-to-left). This makes CSS more flexible and easier to internationalize.`}</p>
    <p>{`Along with the above props, we do have below props to support the logical properties:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`paddingBlockStart`}</inlineCode>{`, `}<inlineCode parentName="td">{`paddingInlineEnd`}</inlineCode>{`, `}<inlineCode parentName="td">{`paddingBlockEnd`}</inlineCode>{`, `}<inlineCode parentName="td">{`paddingInlineStart`}</inlineCode>{`, `}<inlineCode parentName="td">{`paddingBlock`}</inlineCode>{`, `}<inlineCode parentName="td">{`paddingInline`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`number or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A size from our `}<a parentName="td" {...{
              "href": "/foundations/spacing/"
            }}>{`spacing scale`}</a>{`. Can be made responsive by passing an object of breakpoints. Value(s) must be one of `}<inlineCode parentName="td">{`1...10`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`marginBlockStart`}</inlineCode>{`, `}<inlineCode parentName="td">{`marginInlineEnd`}</inlineCode>{`, `}<inlineCode parentName="td">{`marginBlockEnd`}</inlineCode>{`, `}<inlineCode parentName="td">{`marginInlineStart`}</inlineCode>{`, `}<inlineCode parentName="td">{`marginBlock`}</inlineCode>{`, `}<inlineCode parentName="td">{`marginInline`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`number or object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A size from our `}<a parentName="td" {...{
              "href": "/foundations/spacing/"
            }}>{`spacing scale`}</a>{`. Can be made responsive by passing an object of breakpoints. Value(s) must be one of `}<inlineCode parentName="td">{`1...10`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`Note: Note: you can only pass a single value for both end and start for the `}<inlineCode parentName="p">{`paddingBlock`}</inlineCode>{`, `}<inlineCode parentName="p">{`paddingInline`}</inlineCode>{`, `}<inlineCode parentName="p">{`marginBlock`}</inlineCode>{` and `}<inlineCode parentName="p">{`marginInline`}</inlineCode>{` props.`}</p>
    </blockquote>
    <h2 {...{
      "id": "related-components",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#related-components",
        "aria-label": "related components permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Related components`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/stack/"
        }}>{`Stack`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      